<template>
    <div id="page-wrapper">
        <b-form @submit.prevent="onSubmit">
            <div class="logo">
                <img src="../assets/images/transparent-dark-savernake-logo.png" />
            </div>
            <div class="form-group text-center account-error" v-if="!validation.accountState">
                <p>{{validation.accountError}}</p>
            </div>
            <b-form-group label="Email address:">
                <b-form-input v-model="email" type="email" required placeholder="Enter your email address" :state="validation.email" @update="onEmailUpdate"></b-form-input>
                <b-form-invalid-feedback :state="validation.email">
                    {{validation.emailError}}
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Password">
                <b-form-input v-model="password" type="password" required placeholder="Enter your password" @update="onPasswordUpdate"></b-form-input>
            </b-form-group>
            <b-form-checkbox v-model="remember">Keep me logged in</b-form-checkbox>
            <div class="form-group text-right">
                <b-button type="submit" variant="primary">Login</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import { setUser, setToken } from '@/lib/Helpers';

export default {
    data() {
        return {
            email: '',
            password: '',
            remember: false,
            hasSetupListeners: false,
            validation: {
                emailState: true,
                emailError: '',
                passwordState: true,
                passwordError: '',
                accountState: true,
                accountError: ''
            }
        };
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('login', this.onLogin);
    },
    methods: {
        requests() {
            this.$ws.on('login', this.onLogin);
        },
        onSubmit() {
            this.$ws.send({
                accountName: null,
                request: 'Login',
                args: {
                    email: this.email,
                    password: this.password,
                    remember: this.remember
                }
            });
        },
        onLogin(event) {
            if(event.response.hasOwnProperty('token') && event.response.hasOwnProperty('user')) {
                setUser(event.response.user, this.remember);
                setToken(event.response.token, this.remember);

                this.$router.push({name: 'dashboard'}).catch(err => {});
            } else if(event.response.hasOwnProperty('errors')) {
                if(event.response.errors.hasOwnProperty('email')) {
                    this.validation.emailState = false;
                    this.validation.emailError = event.response.errors.email;
                } else if(event.response.errors.hasOwnProperty('password')) {
                    this.validation.passwordState = false;
                    this.validation.passwordError = event.response.errors.password;
                } else if(event.response.errors.hasOwnProperty('account')) {
                    this.validation.accountState = false;
                    this.validation.accountError = event.response.errors.account;
                } else {
                    // this.$notify({
                    //     group: 'primary',
                    //     text: 'An unknown error has occured, please try again later.',
                    //     type: 'error'
                    // });
                }
            } else {
                // this.$notify({
                //     group: 'primary',
                //     text: 'An unknown error has occured, please try again later.',
                //     type: 'error'
                // });
            }
        },
        onEmailUpdate(event) {
            if(!this.validation.emailState) {
                this.validation.emailState = true;
                this.validation.emailError = '';
            }

            if(!this.validation.accountState) {
                this.validation.accountState = true;
                this.validation.accountError = '';
            }
        },
        onPasswordUpdate(event) {
            if(!this.validation.passwordState) {
                this.validation.passwordState = true;
                this.validation.passwordError = '';
            }

            if(!this.validation.accountState) {
                this.validation.accountState = true;
                this.validation.accountError = '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#page-wrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);

    form {
        width: 280px;

        .logo {
            margin-bottom: 30px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .account-error {
            color: red;
        }
    }
}
</style>